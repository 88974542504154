import {
	PUBLIC_AUTH_DOMAIN,
	PUBLIC_FIREBASE_API_KEY,
	PUBLIC_FIREBASE_APP_ID,
	PUBLIC_FIREBASE_PROJECT_ID,
	PUBLIC_SEAMLESS_AUTH_CLIENT_ID,
	// @ts-ignore
} from '$env/static/public'
import type { FirebaseOptions } from '@firebase/app'

export const clientCredentials: FirebaseOptions = {
	apiKey: PUBLIC_FIREBASE_API_KEY,
	authDomain: PUBLIC_AUTH_DOMAIN,
	projectId: PUBLIC_FIREBASE_PROJECT_ID,
	appId: PUBLIC_FIREBASE_APP_ID,
}

export const seamlessAuthClientId = PUBLIC_SEAMLESS_AUTH_CLIENT_ID
