import { browser } from '$app/environment';

const AIRHEART_API_HOST = process.env.AIRHEART_API_HOST || 'http://localhost:8080/graphql';
const AIRHEART_SSR_API_HOST = process.env.AIRHEART_SSR_API_HOST || AIRHEART_API_HOST;

const isSSR = !browser;

export function buildUrl() {
	switch (import.meta.env.MODE) {
		case 'development':
		case 'test':
			return 'http://localhost:8080/api/graphql';
		case 'production':
			return isSSR ? AIRHEART_SSR_API_HOST : AIRHEART_API_HOST;
	}
}
