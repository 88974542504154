import { getIdTokenWithExpiresAt } from '$lib/authentication/helpers'
import type { CurrentUserQuery } from '$lib/queries/generated/currentUser'
import type { User } from 'firebase/auth'
import { get, writable, type Readable } from 'svelte/store'

export type CurrentUser = CurrentUserQuery['currentUser']
export type SignInState = 'signIn' | 'signUp' | 'resetPw'
export type AuthCallback = (err: Error | null) => void | Promise<void>

export type AuthState = {
	token: string | null
	expiredAt: number | null
	timezone: string | null
	authDidInitialize: boolean
	authErrorMessage: string | null
	signedIn: boolean
	currentUser: CurrentUser | null
	authenticating: boolean
	showEmailConfirmation: boolean
	isCompletingSignIn: boolean
	authCallbacks: AuthCallback[]
}

function createInitialState() {
	return {
		// Token state
		token: null,
		expiredAt: null,
		timezone: null,

		authDidInitialize: false,
		authErrorMessage: null,
		// Authentication state
		signedIn: false,
		currentUser: null,
		authenticating: false,

		// Email OTP flow state
		showEmailConfirmation: false,
		isCompletingSignIn: false, // replace with `authenticating`
		authCallbacks: [],
	}
}

const store = writable<AuthState>(createInitialState())
const set = (newState: Partial<AuthState>) => store.update((state) => ({ ...state, ...newState }))

const setCurrentUser = (currentUser: CurrentUser | null) =>
	set({
		currentUser,
		signedIn: currentUser !== null,
		authenticating: false,
		authErrorMessage: null,
		authDidInitialize: true,
		isCompletingSignIn: false,
		showEmailConfirmation: false,
	})

const resolveAuthCallbacks = async (err: Error | null) => {
	const currentState = get(state)
	const cbs = currentState.authCallbacks || []
	for (const cb of cbs.filter(Boolean)) {
		await cb(err)
	}

	store.set({ ...currentState, authCallbacks: [] })
}

const addAuthCallback = (cb: AuthCallback) => {
	store.update((state) => {
		const cbs = state.authCallbacks || []
		cbs.push(cb)
		return { ...state, authCallbacks: cbs }
	})
}

const setAuthError = (msg: string) => set({ authErrorMessage: msg, signedIn: false })
const clearAuthError = () => set({ authErrorMessage: null })
const setAuthenticating = (authenticating: boolean) => set({ authenticating, signedIn: false })
const setInitialized = () => set({ authDidInitialize: true })
const clearCurrentUser = () =>
	set({
		currentUser: null,
		signedIn: false,
		authenticating: false,
		authDidInitialize: false,
	})

const setShowEmailConfirmation = (showEmailConfirmation: boolean) => set({ showEmailConfirmation })

const loadCurrentUser = async (user: User) => {
	console.log('[AUTH] Loading current user ', user.email)
	const currentUser: CurrentUser = {
		id: user.uid,
		email: user.email,
		emailVerified: user.emailVerified,
		fullName: user.displayName,
		currentName: user.displayName,
		currentProfilePictureUrl: user.photoURL,
		pictureURL: user.photoURL,
		hasSellerId: false,
		__typename: 'User',
	}

	set({
		authenticating: false,
		signedIn: true,
		currentUser,
		authDidInitialize: true,
		authErrorMessage: null,
		isCompletingSignIn: false,
		showEmailConfirmation: false,
	})
	return currentUser
}

const setAuthToken = (token: string, expiresAt: number, timezone: string): void => {
	set({ token, expiredAt: expiresAt, timezone })
}

const getAuthToken = (): [string, number, string] => {
	return [get(state).token, get(state).expiredAt, get(state).timezone]
}

const clearAuthToken = () => set({ token: null, expiredAt: null })

const state: Readable<AuthState> & {
	setCurrentUser: typeof setCurrentUser
	addAuthCallback: typeof addAuthCallback
	resolveAuthCallbacks: typeof resolveAuthCallbacks
	setAuthenticating: typeof setAuthenticating
	setInitialized: typeof setInitialized
	clearCurrentUser: typeof clearCurrentUser
	setShowEmailConfirmation: typeof setShowEmailConfirmation
	loadCurrentUser: typeof loadCurrentUser
	setAuthError: typeof setAuthError
	clearAuthError: typeof clearAuthError
	clearAuthToken: typeof clearAuthToken
	getAuthToken: typeof getAuthToken
	setAuthToken: typeof setAuthToken
} = {
	subscribe: store.subscribe,
	setCurrentUser,
	addAuthCallback,
	resolveAuthCallbacks,
	setAuthenticating,
	setInitialized,
	clearCurrentUser,
	setShowEmailConfirmation,
	loadCurrentUser,
	setAuthError,
	clearAuthError,
	clearAuthToken,
	getAuthToken,
	setAuthToken,
}

export default state
