import type {
	CreateCouponMutation,
	CreateCouponMutationVariables,
} from '$lib/queries/generated/PublishListing'
import {
	FetchCouponCodesDocument,
	type FetchCouponCodesQuery,
	type FetchCouponCodesQueryVariables,
} from '$lib/queries/generated/QueryStripeSeller'
import type { Cache } from '@urql/exchange-graphcache'

export function fetchCouponCodesHandler(
	result: CreateCouponMutation,
	args: CreateCouponMutationVariables,
	cache: Cache,
) {
	let coupon = result?.createCoupon

	// Add the new coupon to the cache
	cache.updateQuery<FetchCouponCodesQuery, FetchCouponCodesQueryVariables>(
		{ query: FetchCouponCodesDocument, variables: {} },
		(data) => {
			if (data && data.fetchCouponCodes) {
				return {
					fetchCouponCodes: {
						...data.fetchCouponCodes,
						coupon,
					},
				}
			}
			return data
		},
	)
}
