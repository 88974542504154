import type { Cache } from '@urql/exchange-graphcache'
import {
	MapViewDocument,
	type MapViewQuery,
	type MapViewQueryVariables,
} from '$lib/queries/generated/QueryMapView'
import type {
	AddToListMutation,
	AddToListMutationVariables,
	RemoveFromListMutation,
	RemoveFromListMutationVariables,
} from '$lib/queries/generated/UpdateBlock'
import {
	BlockDocument,
	type BlockQuery,
	type BlockQueryVariables,
} from '$lib/queries/generated/QueryBlock'
import type {
	InsertCustomRegionBlockMutation,
	InsertCustomRegionBlockMutationVariables,
	InsertDayBlockMutation,
	InsertDayBlockMutationVariables,
	InsertDestinationBlockMutation,
	InsertDestinationBlockMutationVariables,
	InsertHighlightBlockMutation,
	InsertHighlightBlockMutationVariables,
	InsertListBlockMutation,
	InsertListBlockMutationVariables,
	InsertPinBlockMutation,
	InsertPinBlockMutationVariables,
	InsertPlaceBlockMutation,
	InsertSourceBlockMutation,
	InsertSourceBlockMutationVariables,
} from '$lib/queries/generated/InsertBlock'
import {
	TripsDocument,
	type TripsQuery,
	type TripsQueryVariables,
} from '$lib/queries/generated/QueryTrips'
import type { TripEdgeFragment } from '$lib/queries/fragments/generated/TripEdge'
import { ensureTripsConnection } from './trips'
import type {
	DeleteBlockMutation,
	DeleteBlockMutationVariables,
} from '$lib/queries/generated/deleteBlock'
import { BlockType } from '../enums'
import {
	TripDocument,
	type TripQuery,
	type TripQueryVariables,
} from '$lib/queries/generated/QueryTrip'
import type { LayeredBlocksFieldsFragment } from '$lib/queries/fragments/generated/LayeredBlocks'
import type { Block, BlockPositionUpdateInput } from '../types'
import type {
	UpdateBlockPositionsMutation,
	UpdateBlockPositionsMutationVariables,
} from '$lib/queries/generated/UpdateBlockPositions'
import type { ExtraBlocksFieldsFragment } from '$lib/queries/fragments/generated/ExtraBlockFields'
import type { ItineraryBlocksFieldsFragment } from '$lib/queries/fragments/generated/ItineraryBlockFields'
import type { DestinationSegmentFieldsFragment } from '$lib/queries/fragments/generated/DestinationSegmentFields'
import type { SourceBlockFieldsFragment } from '$lib/queries/fragments/generated/SourceBlockFields'
import type { HighlightBlockFieldsFragment } from '$lib/queries/fragments/generated/HighlightBlockFields'
import type {
	UpsertRouteBlockMutation,
	UpsertRouteBlockMutationVariables,
} from '$lib/queries/generated/UpsertRoute'
import type { RouteBlockFieldsFragment } from '$lib/queries/fragments/generated/RouteBlockFields'

// highlights query
// trip query
// trips query
// block query
// map query
export function addToListHandler(
	result: AddToListMutation,
	args: AddToListMutationVariables,
	cache: Cache,
) {
	let list = result?.addToList
	let block = result?.addToList?.childBlocks?.find((b) => b.id === args?.blockID)

	if (list == null) return

	// Remove old from trip
	updateTripQueryCache(cache, args?.tripID, args?.parentID, block, false)
	updateTripQueryCache(cache, args?.tripID, args?.parentID, list, false)

	// add new to trip
	updateTripQueryCache(cache, args?.tripID, args?.parentID, list, true)

	// Update the list with the new child block
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args.listID } },
		(data) => {
			if (data && data.block) {
				return {
					block: {
						...data.block,
						...list,
					},
				}
			}
			return data
		},
	)

	// Update the block with the new parent list
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args.blockID } },
		(data) => {
			if (data && data.block) {
				return {
					block: {
						...data.block,
						parentList: {
							__typename: 'Block',
							id: args.listID,
							title: list?.title,
							description: list?.description,
							color: list?.color,
							icon: list?.icon,
						},
					},
				}
			}
			return data
		},
	)
}

// highlights query
// trip query
// trips query
// block query
// map query
export function removeFromListHandler(
	result: RemoveFromListMutation,
	args: RemoveFromListMutationVariables,
	cache: Cache,
) {
	let list = result.removeFromList?.listItem
	let block = result?.removeFromList?.blockItem

	if (list == null) return

	// Remove old from trip
	updateTripQueryCache(cache, args?.tripID, args?.listID, block, false, true)

	// add new to trip
	updateTripQueryCache(cache, args?.tripID, args?.parentID, block, true, false)

	updateBlockParentIdMapView(cache, args.tripID, { id: block?.id, parentId: args?.parentID })

	// remove the list from the block's parentList
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args.blockID } },
		(data) => {
			if (data && data.block) {
				return {
					block: {
						...data.block,
						parentList: null,
						lists: [...data.block.lists],
					},
				}
			}
			return data
		},
	)

	// remove the block from the list's childBlocks
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args.listID } },
		(data) => {
			if (data && data.block) {
				return {
					block: {
						...data.block,
						childBlocks: data.block.childBlocks.filter((b) => b.id !== args.blockID),
					},
				}
			}
			return data
		},
	)
}

// highlights query
// trip query
// trips query
// block query
// map query
export function insertPinBlockHandler(
	result: InsertPinBlockMutation,
	args: InsertPinBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertPinBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args?.tripID, args?.input?.parentID, newBlock, true)

	// Update the block with the new child block
	updateBlockCheckIfDestination(cache, args.input.parentID, newBlock)

	// add to map view
	updateMapViewQueryCache(cache, args.tripID, newBlock)
}

export function insertPlaceBlockHandler(
	result: InsertPlaceBlockMutation,
	args: InsertPinBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertPlaceBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args.tripID, args.input.parentID, newBlock, true)

	// Update the block with the new child block
	updateBlockCheckIfDestination(cache, args.input.parentID, newBlock)

	// add to map view
	updateMapViewQueryCache(cache, args.tripID, newBlock)
}

export function insertListBlockHandler(
	result: InsertListBlockMutation,
	args: InsertListBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertListBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args.tripID, args.input.parentID, newBlock, true)

	// Update the block with the new child block
	updateBlockCheckIfDestination(cache, args.input.parentID, newBlock)

	// add to map view
	updateMapViewQueryCache(cache, args.tripID, newBlock)
}

export function insertDestinationBlockHandler(
	result: InsertDestinationBlockMutation,
	args: InsertDestinationBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertDestinationBlock?.destinationBlock
	if (newBlock == null) return

	cache.updateQuery<TripQuery, TripQueryVariables>(
		{ query: TripDocument, variables: { tripId: args?.tripID } },
		(data) => {
			if (data?.trip) {
				const updatedOrderedBlocks = data?.trip?.orderedBlocks?.map((orderedBlock) => {
					if (orderedBlock?.rootBlock?.id === args?.input?.parentID) {
						const newDestinationSegment: DestinationSegmentFieldsFragment =
							result.insertDestinationBlock

						// Check if the newDestinationSegment already exists
						const segmentExists =
							orderedBlock?.destinationSegments?.some(
								(segment) => segment.id === newDestinationSegment.id,
							) || false

						if (!segmentExists) {
							return {
								...orderedBlock,
								destinationSegments: [...orderedBlock?.destinationSegments, newDestinationSegment],
							}
						}
						console.warn(
							`Destination segment with ID ${newDestinationSegment?.id} already exists in the ordered block`,
						)
					}
					return orderedBlock
				})

				return {
					...data,
					trip: {
						...data?.trip,
						orderedBlocks: updatedOrderedBlocks,
					},
				}
			}
			return data
		},
	)

	// add to map view
	updateMapViewQueryCache(cache, args?.tripID, newBlock)

	// Update the trips
	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		if (!data) return data

		data = ensureTripsConnection(data)
		if (!data) return data

		const publishedEdges = data?.published?.edges ?? []
		const planningEdges = data?.planning?.edges ?? []
		const purchasedEdges = data?.purchased?.edges ?? []

		// Update the edges in each list
		const newPublishedEdges = updateTripEdge(publishedEdges, args?.tripID)
		const newPlanningEdges = updateTripEdge(planningEdges, args?.tripID)
		const newPurchasedEdges = updateTripEdge(purchasedEdges, args?.tripID)

		// Update the data with immutable updates
		const newData = {
			...data,
			published: {
				...data?.published,
				edges: newPublishedEdges,
			},
			planning: {
				...data?.planning,
				edges: newPlanningEdges,
			},
			purchased: {
				...data?.purchased,
				edges: newPurchasedEdges,
			},
		}

		return newData
	})
}

export function insertCustomRegionBlockHandler(
	result: InsertCustomRegionBlockMutation,
	args: InsertCustomRegionBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertCustomRegionBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args?.tripID, args?.input?.parentID, newBlock, true)

	// Update the block with the new child block
	updateBlockCheckIfDestination(cache, args?.input?.parentID, newBlock)

	// add to map view
	updateMapViewQueryCache(cache, args?.tripID, newBlock)
}

// Updated insertCustomRegionBlockHandler
export function insertDayBlockHandler(
	result: InsertDayBlockMutation,
	args: InsertDayBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertDayBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args?.tripID, args?.input?.parentID, newBlock, true)

	// Update the block with the new child block
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args?.destinationID } },
		(data) => {
			if (data && data?.block) {
				const itineraryBlock = data?.block?.itineraryBlocks?.[0]

				if (itineraryBlock) {
					// Check if the new block already exists in the childBlocks
					const blockExists = itineraryBlock?.childBlocks?.some(
						(child) => child.id === newBlock?.id,
					)

					if (!blockExists) {
						// Add the new block to the 'Itinerary' block's childBlocks
						return {
							block: {
								...data.block,
								itineraryBlocks: [
									{
										...itineraryBlock,
										childBlocks: [...(itineraryBlock.childBlocks || []), newBlock],
									},
								],
							},
						}
					} else {
						console.warn(`Block with ID ${newBlock?.id} already exists in the Itinerary block`)
						return data
					}
				} else {
					console.warn("No 'Itinerary' block found in the Destination block")
					return data
				}
			}
			return data
		},
	)

	updateMapViewQueryCache(cache, args.tripID, newBlock)
}

export function insertHighlightBlockHandler(
	result: InsertHighlightBlockMutation,
	args: InsertHighlightBlockMutationVariables,
	cache: Cache,
) {
	const highlights = result?.insertHighlightBlock?.highlightGroups?.flatMap(
		(group) => group?.highlightBlocks,
	)
	const newBlocks = highlights?.filter(
		(highlight) => highlight?.highlight?.cloudflareID === args?.input?.media?.[0]?.id,
	)
	newBlocks?.forEach((newBlock) => {
		updateMapViewQueryCache(cache, args.tripID, newBlock)
	})

	// Update TripQuery cache
	// updateTripQueryCache(cache, args.tripID, args.input.parentID, newBlock, true)

	// Update the block with the new child block
	// cache.updateQuery<BlockQuery, BlockQueryVariables>(
	// 	{ query: BlockDocument, variables: { id: args?.input?.parentID } },
	// 	(data) => {
	// 		if (data && data.block) {
	// 			const highlightExists =
	// 				data.block.highlights?.some((highlight) => highlight.id === newBlock.id) || false

	// 			if (!highlightExists) {
	// 				return {
	// 					block: {
	// 						...data.block,
	// 						highlights: [...(data.block.highlights || []), newBlock],
	// 					},
	// 				}
	// 			}
	// 		}
	// 		return data
	// 	},
	// )

	// cache.updateQuery<HighlightsQuery, HighlightsQueryVariables>(
	// 	{ query: HighlightsDocument, variables: { id: args?.tripID } },
	// 	(data) => {
	// 		if (data && data.highlights) {
	// 			const highlightGroup = data.highlights.highlightGroups?.find(
	// 				(group) => group?.parentBlock?.id === args?.input?.parentID,
	// 			)

	// 			console.log('highlightGroup', highlightGroup)

	// 			if (!highlightGroup) return data

	// 			const highlightExists =
	// 				highlightGroup.highlightBlocks?.some((highlight) => highlight.id === newBlock.id) || false

	// 			console.log('highlightExists', highlightExists)

	// 			if (!highlightExists) {
	// 				return {
	// 					...data,
	// 					highlights: {
	// 						...data.highlights,
	// 						highlightGroups: data.highlights.highlightGroups?.map((group) =>
	// 							group?.parentBlock?.id === args?.input?.parentID
	// 								? {
	// 										...group,
	// 										highlightBlocks: [...(group.highlightBlocks || []), newBlock],
	// 								  }
	// 								: group,
	// 						),
	// 					},
	// 				}
	// 			}
	// 		}
	// 		return data
	// 	},
	// )
}

export function insertSourceBlockHandler(
	result: InsertSourceBlockMutation,
	args: InsertSourceBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.insertSourceBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args.tripID, args.input?.parentID, newBlock, true)

	// Update the block with the new child block
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args?.input?.parentID } },
		(data) => {
			if (data && data.block) {
				const sourceExists =
					data.block.sources?.some((source) => source.id === newBlock.id) || false

				if (!sourceExists) {
					return {
						block: {
							...data.block,
							sources: [...(data.block?.sources || []), newBlock],
						},
					}
				}
			}
			return data
		},
	)

	updateMapViewQueryCache(cache, args.tripID, newBlock)
}

export function upsertRouteBlockHandler(
	result: UpsertRouteBlockMutation,
	args: UpsertRouteBlockMutationVariables,
	cache: Cache,
) {
	const newBlock = result?.upsertRouteBlock
	if (newBlock == null) return

	// Update TripQuery cache
	updateTripQueryCache(cache, args?.tripID, args?.startBlockID, newBlock, false)
	// updateTripQueryCache(cache, args.tripID, args.startBlockID, newBlock, true)

	// Update the block with the new child block
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: args?.startBlockID } },
		(data) => {
			if (data && data.block) {
				const routeExists = data.block?.routeAfter?.id === newBlock.id || false

				if (!routeExists) {
					return {
						block: {
							...data.block,
							routeAfter: newBlock,
						},
					}
				}
			}
			return data
		},
	)

	updateMapViewQueryCache(cache, args?.tripID, newBlock)
}

export function deleteBlockHandler(
	result: DeleteBlockMutation,
	args: DeleteBlockMutationVariables,
	cache: Cache,
) {
	const tripId = args.tripId
	const deletedBlockId = result?.deleteBlock

	// Invalidate the block
	cache.invalidate({
		__typename: 'Block',
		id: args.id as string,
	})

	deletedBlockId.forEach((id) => {
		cache.invalidate({
			__typename: 'Block',
			id: id as string,
		})
	})

	if (args?.blockType === BlockType.Source) {
		// Update TripQuery cache if Source block
		cache.updateQuery<TripQuery, TripQueryVariables>(
			{ query: TripDocument, variables: { tripId: args?.id } },
			(data) => {
				if (data?.trip) {
					const updatedOrderedBlocks = data?.trip?.orderedBlocks?.map((orderedBlock) => ({
						...orderedBlock,
						destinationSegments: orderedBlock?.destinationSegments?.map((segment) => {
							let updatedExtraBlocks = decrementNumInListForParent(
								segment?.extraBlocks,
								args?.parentId,
							)
							let updatedItineraryBlocks = decrementNumInListForParent(
								segment?.extraBlocks,
								args?.parentId,
							)

							return {
								...segment,
								extraBlocks: updatedExtraBlocks,
								itineraryBlocks: updatedItineraryBlocks,
							}
						}),
					}))

					return {
						trip: {
							...data.trip,
							orderedBlocks: updatedOrderedBlocks,
						},
					}
				}
				return data
			},
		)
	}

	if (args.blockType !== BlockType.Destination) return

	// Update the trips
	cache.updateQuery<TripsQuery, TripsQueryVariables>({ query: TripsDocument }, (data) => {
		if (!data) return data

		data = ensureTripsConnection(data)
		if (!data) return data

		const publishedEdges = data.published?.edges ?? []
		const planningEdges = data.planning?.edges ?? []
		const purchasedEdges = data.purchased?.edges ?? []

		// Update the edges in each list by decrementing numDestinations
		const newPublishedEdges = updateTripEdge(publishedEdges, tripId, false)
		const newPlanningEdges = updateTripEdge(planningEdges, tripId, false)
		const newPurchasedEdges = updateTripEdge(purchasedEdges, tripId, false)

		// Update the data with immutable updates
		const newData = {
			...data,
			published: {
				...data.published,
				edges: newPublishedEdges,
			},
			planning: {
				...data.planning,
				edges: newPlanningEdges,
			},
			purchased: {
				...data.purchased,
				edges: newPurchasedEdges,
			},
		}

		return newData
	})
}

export function updateBlockPositionsHandler(
	result: UpdateBlockPositionsMutation,
	args: UpdateBlockPositionsMutationVariables,
	cache: Cache,
) {
	const updates = Array.isArray(args?.input) ? args?.input : [args?.input]

	if (args?.isDestination) {
		updatePositionsInTripQueryCacheDestination(cache, args.tripID, updates)
	} else {
		const mapUpdates = updates?.map((update) => ({ id: update.id, parentId: update.parentId }))
		updatePositionsInTripQueryCache(cache, args?.tripID, updates)

		result?.updateBlockPositions?.forEach((id) => {
			cache.invalidate({
				__typename: 'Block',
				id: id as string,
			})
		})

		mapUpdates?.forEach((update) => {
			updateBlockParentIdMapView(cache, args.tripID, update)
		})
	}
}

function updateMapViewQueryCache(
	cache: Cache,
	tripId: string,
	newBlock: LayeredBlocksFieldsFragment | SourceBlockFieldsFragment | HighlightBlockFieldsFragment,
) {
	cache.updateQuery<MapViewQuery, MapViewQueryVariables>(
		{ query: MapViewDocument, variables: { id: tripId } },
		(data) => {
			if (data?.mapView) {
				// Check if the block already exists
				// const blockExists = data.mapView?.some((b) => b.id === newBlock.id) || false

				return {
					mapView: {
						...data?.mapView?.filter((b) => b.id !== newBlock?.id),
						block: newBlock,
					},
				}
			}
			return data
		},
	)
}

function updateBlocksRecursively(
	blocks: Partial<LayeredBlocksFieldsFragment>[] | Partial<Block>[],
	parentId: string,
	newItem: any,
	isAdding: boolean,
	updateNumInList: boolean = false,
): any[] {
	return blocks?.map((currentBlock) => {
		if (currentBlock.id === parentId) {
			const updatedBlock = { ...currentBlock }

			switch (newItem?.blockType) {
				case BlockType.Highlight:
					if (isAdding) {
						const highlightExists =
							updatedBlock.highlights?.some((highlight) => highlight.id === newItem?.id) || false
						if (!highlightExists) {
							updatedBlock.highlights = [...(updatedBlock.highlights || []), newItem]
						}
					} else {
						updatedBlock.highlights = (updatedBlock.highlights || []).filter(
							(highlight: any) => highlight.id !== newItem?.id,
						)
					}
					break
				case BlockType.Source:
					if (isAdding) {
						const sourceExists =
							updatedBlock.sources?.some((source) => source.id === newItem?.id) || false
						if (!sourceExists) {
							updatedBlock.sources = [...(updatedBlock?.sources || []), newItem]
						}
					} else {
						updatedBlock.sources = (updatedBlock?.sources || []).filter(
							(source: any) => source.id !== newItem.id,
						)
					}
					break

				case BlockType.Route:
					if (isAdding) {
						const routeExists = updatedBlock?.routeAfter?.id === newItem.id || false
						if (!routeExists) {
							updatedBlock.routeAfter = newItem
						}
					} else {
						updatedBlock.routeAfter = null
					}
					break
				default:
					if (isAdding) {
						const childExists =
							updatedBlock.childBlocks?.some((child) => child.id === newItem.id) || false
						if (!childExists) {
							updatedBlock.childBlocks = [...(updatedBlock.childBlocks || []), newItem]
						}
					} else {
						updatedBlock.childBlocks = (updatedBlock.childBlocks || []).filter(
							(child: any) => child.id !== newItem.id,
						)
					}
					break
			}

			// Update the NumInList if the parent block is of type LIST and updateNumInList is true
			if (
				updateNumInList &&
				updatedBlock.blockType === BlockType.List &&
				newItem.blockType === BlockType.Source
			) {
				if (isAdding) {
					updatedBlock.numInList = (updatedBlock?.numInList || 0) + 1
				} else {
					updatedBlock.numInList = Math.max((updatedBlock?.numInList || 0) - 1, 0)
				}
			}

			return updatedBlock
		}

		// Recursively update childBlocks
		if (currentBlock.childBlocks) {
			return {
				...currentBlock,
				childBlocks: updateBlocksRecursively(currentBlock.childBlocks, parentId, newItem, isAdding),
			}
		}

		return currentBlock
	})
}

// Reusable function to update TripQuery cache
export function updateTripQueryCache(
	cache: Cache,
	tripId: string,
	parentId: string,
	newItem: LayeredBlocksFieldsFragment | SourceBlockFieldsFragment | RouteBlockFieldsFragment,
	isAdding: boolean,
	updateNumInList: boolean = false,
) {
	cache.updateQuery<TripQuery, TripQueryVariables>(
		{ query: TripDocument, variables: { tripId } },
		(data) => {
			if (data?.trip) {
				const updatedOrderedBlocks = data?.trip?.orderedBlocks?.map((orderedBlock) => ({
					...orderedBlock,
					destinationSegments: orderedBlock?.destinationSegments?.map((segment) => {
						// Update extraBlocks
						const updatedExtraBlocks = updateBlocksRecursively(
							segment?.extraBlocks,
							parentId,
							newItem,
							isAdding,
							updateNumInList,
						)

						// Update itineraryBlocks
						const updatedItineraryBlocks = updateBlocksRecursively(
							segment.itineraryBlocks,
							parentId,
							newItem,
							isAdding,
							updateNumInList,
						)

						return {
							...segment,
							extraBlocks: updatedExtraBlocks,
							itineraryBlocks: updatedItineraryBlocks,
						}
					}),
				}))

				return {
					trip: {
						...data.trip,
						orderedBlocks: updatedOrderedBlocks,
						unpublishedListing: {
							...data.trip.unpublishedListing,
							hasUnpublishedChanges: true,
						},
					},
				}
			}
			return data
		},
	)
}

function updateBlockParentIdMapView(
	cache: Cache,
	tripId: string,
	blockToUpdate: { id: string; parentId: string },
) {
	cache.updateQuery<MapViewQuery, MapViewQueryVariables>(
		{ query: MapViewDocument, variables: { id: tripId } },
		(data) => {
			if (data?.mapView) {
				const blockExists = data?.mapView?.some((block) => block.id === blockToUpdate.id)

				if (blockExists) {
					const updatedBlocks = data?.mapView?.map((block) =>
						block.id === blockToUpdate.id ? { ...block, parentId: blockToUpdate.parentId } : block,
					)

					return {
						mapView: {
							...data?.mapView,
							blocks: updatedBlocks,
						},
					}
				}
			}
			return data
		},
	)
}

function updateBlockCheckIfDestination(
	cache: Cache,
	parentID: string,
	newBlock: LayeredBlocksFieldsFragment,
) {
	cache.updateQuery<BlockQuery, BlockQueryVariables>(
		{ query: BlockDocument, variables: { id: parentID } },
		(data) => {
			if (data && data.block) {
				if (data.block.blockType === BlockType.Destination) {
					// Find the 'Extra' child block
					const extraBlock = data.block?.extraBlocks?.[0]

					if (extraBlock) {
						// Check if the new block ID already exists in the child blocks
						const blockExists =
							extraBlock.childBlocks?.some((block) => block.id === newBlock.id) || false

						if (!blockExists) {
							return {
								block: {
									...data.block,
									extraBlocks: [
										{
											...extraBlock,
											childBlocks: [...(extraBlock.childBlocks || []), newBlock],
										},
									],
								},
							}
						} else {
							console.warn(`Block with ID ${newBlock.id} already exists in the Extra block`)
							return data
						}
					} else {
						console.warn("No 'Extra' block found in the Destination block")
						return data
					}
				} else {
					// If not a Destination block, check if the new block ID already exists in the child blocks
					const blockExists =
						data.block.childBlocks?.some((block) => block.id === newBlock.id) || false

					if (!blockExists) {
						return {
							block: {
								...data.block,
								childBlocks: [...(data.block?.childBlocks || []), newBlock],
							},
						}
					} else {
						console.warn(`Block with ID ${newBlock.id} already exists in the parent block`)
						return data
					}
				}
			}
			return data
		},
	)
}

// Helper function to recursively update blocks across both trees
function updateBlockPositionsRecursively(
	extraBlocks: ExtraBlocksFieldsFragment[],
	itineraryBlocks: ItineraryBlocksFieldsFragment[],
	blockUpdates: BlockPositionUpdateInput[],
): { extraBlocks: ExtraBlocksFieldsFragment[]; itineraryBlocks: ItineraryBlocksFieldsFragment[] } {
	const updatedExtraBlocks = [...extraBlocks]
	const updatedItineraryBlocks = [...itineraryBlocks]

	blockUpdates.forEach((update) => {
		const { id, parentId, position } = update
		let blockToMove =
			findAndRemoveBlock(updatedExtraBlocks, id) || findAndRemoveBlock(updatedItineraryBlocks, id)

		if (blockToMove) {
			const newParent =
				findBlock(updatedExtraBlocks, parentId) || findBlock(updatedItineraryBlocks, parentId)
			if (newParent) {
				if (!newParent?.childBlocks) {
					newParent.childBlocks = []
				}
				newParent?.childBlocks?.splice(position, 0, blockToMove)
			}
		}
	})

	return { extraBlocks: updatedExtraBlocks, itineraryBlocks: updatedItineraryBlocks }
}

// Helper function to find and remove a block from the tree
function findAndRemoveBlock(blocks: any[], blockID: string): any {
	for (let i = 0; i < blocks?.length; i++) {
		if (blocks?.[i]?.id === blockID) {
			return blocks?.splice(i, 1)?.[0]
		}
		if (blocks?.[i]?.childBlocks) {
			const found = findAndRemoveBlock(blocks?.[i]?.childBlocks, blockID)
			if (found) return found
		}
	}
	return null
}

// Helper function to find a block in the tree
function findBlock(blocks: any[], blockID: string): any {
	for (const block of blocks) {
		if (block.id === blockID) {
			return block
		}
		if (block.childBlocks) {
			const found = findBlock(block.childBlocks, blockID)
			if (found) return found
		}
	}
	return null
}

export function updatePositionsInTripQueryCacheDestination(
	cache: Cache,
	tripId: string,
	blockUpdates: BlockPositionUpdateInput[],
) {
	cache.updateQuery<TripQuery, TripQueryVariables>(
		{ query: TripDocument, variables: { tripId } },
		(data) => {
			if (data?.trip) {
				let updatedOrderedBlocks = data?.trip?.orderedBlocks?.map((orderedBlock) => {
					let updatedSegments = orderedBlock?.destinationSegments?.map((segment) => {
						const blockToUpdate = blockUpdates?.find(
							(update) => update.id === segment?.destinationBlock?.id,
						)

						return blockToUpdate
							? {
									...segment,
									destinationBlock: {
										...segment.destinationBlock,
										position: blockToUpdate?.position,
									},
							  }
							: segment
					})

					updatedSegments = updatedSegments?.sort(
						(a, b) => a.destinationBlock.position - b.destinationBlock.position,
					)

					return {
						...orderedBlock,
						destinationSegments: updatedSegments,
					}
				})

				const newData = {
					...data.trip,
					orderedBlocks: updatedOrderedBlocks,
					unpublishedListing: {
						...data.trip.unpublishedListing,
						hasUnpublishedChanges: true,
					},
				}
				return {
					trip: newData,
				}
			}
			return data
		},
	)
}

// Reusable function to update TripQuery cache
export function updatePositionsInTripQueryCache(
	cache: Cache,
	tripId: string,
	blockUpdates: BlockPositionUpdateInput[],
) {
	cache.updateQuery<TripQuery, TripQueryVariables>(
		{ query: TripDocument, variables: { tripId } },
		(data) => {
			if (data?.trip) {
				const updatedOrderedBlocks = data?.trip?.orderedBlocks?.map((orderedBlock) => ({
					...orderedBlock,
					destinationSegments: orderedBlock?.destinationSegments?.map((segment) => {
						// Update both extraBlocks and itineraryBlocks simultaneously
						const { extraBlocks: updatedExtraBlocks, itineraryBlocks: updatedItineraryBlocks } =
							updateBlockPositionsRecursively(
								segment.extraBlocks,
								segment.itineraryBlocks,
								blockUpdates,
							)

						return {
							...segment,
							extraBlocks: updatedExtraBlocks,
							itineraryBlocks: updatedItineraryBlocks,
						}
					}),
				}))

				return {
					trip: {
						...data.trip,
						orderedBlocks: updatedOrderedBlocks,
						unpublishedListing: {
							...data.trip?.unpublishedListing,
							hasUnpublishedChanges: true,
						},
					},
				}
			}
			return data
		},
	)
}

function updateTripEdge(
	edges: TripEdgeFragment['edges'],
	tripId: string,
	increment: boolean = true,
) {
	return edges?.map((edge) => {
		if (edge.node.id === tripId) {
			return {
				...edge,
				node: {
					...edge.node,
					numDestinations: (edge.node?.numDestinations ?? 0) + (increment ? 1 : -1),
				},
			}
		}
		return edge
	})
}

function decrementNumInListForParent(
	blocks: Partial<LayeredBlocksFieldsFragment>[] | Partial<Block>[],
	parentId: string,
): any[] {
	return blocks?.map((block) => {
		if (block.id === parentId && block.blockType === BlockType.List) {
			// Decrement numInList but ensure it doesn't go below 0
			return {
				...block,
				numInList: Math.max((block.numInList || 0) - 1, 0),
			}
		}

		// Recursively search in childBlocks
		if (block.childBlocks) {
			return {
				...block,
				childBlocks: decrementNumInListForParent(block.childBlocks, parentId),
			}
		}

		return block
	})
}
